@import "./variables";

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  font-family: var(--font-family-sans-serif);
  padding: 0;
  margin: 0;
}

.hidden {
  overflow: hidden;
}

#root {
  min-height: 100%;
  position: relative;
}

a {
  text-decoration: none !important;
}

main.in {
  padding-left: 60px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: $blue05;
}

.uppercase:first-letter {
  text-transform: uppercase;
}

option::first-letter {
  text-transform: uppercase;
}

.hint-tabs .wr .action-buttons button,
.hint-testsettings .tbody-tr:first-child .icon-setup,
.hint-test .wr_tabs_left .tabs-item:last-child,
.hint-groups .wr_tabs_left .tabs,
.hint-plan .unit_head-table .wr .button:first-child {
  z-index: 999;
  pointer-events: none;
  background: #fff;
}

input,
button {
  font-family: "Proxima-Nova", sans-serif !important;
  outline: none;
  font-size: 0.875rem !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

h1,
h2,
p {
  color: $dark;
}

h1 {
  font-style: normal;
  font-weight: bold !important;
  font-size: 38px !important;
  line-height: 1.6;
  color: $dark;
}

h2 {
  font-style: normal;
  font-weight: bold !important;
  font-size: 1.5rem !important;
  line-height: 1.6;
  color: $dark;
}

a {
  transition: 0.2s;
  text-decoration: none;
  color: $dark;

  &:hover {
    color: inherit;
  }
}

.body-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.7px;
  color: $dark;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.8;
  color: $dark;
}

.unit {
  width: 100%;
}

.wr {
  width: 1224px;
  margin: 0 auto;
  max-width: 96%;
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;

  .action-buttons {
    .button {
      margin-right: 0 !important;
    }
  }
}

.container-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
}

.container-checkbox * {
  outline: none;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkmark:after {
    display: block;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  &:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    outline: none;
  }
}

.checkmark-all {
  &:after {
    transform: rotate(90deg);
    border-width: 0 2px 0 0;
  }
}

.wrapper-window {
  max-width: 288px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;

  & .button {
    margin-top: 32px;
  }
}

.wrapper-window-title {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 1.6;
  text-align: center;
  color: $dark;
}

.sub-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.8;
  color: $dark40;

  a {
    margin-top: 16px;
    text-decoration: none;
  }
}

.red {
  color: $red !important;
}
.row-tabs {
  display: flex;
}

.desc i.icon-sort,
i.desc {
  transform: rotate(180deg);
}

.wr_tabs {
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;

  .button {
    position: absolute;
    right: 0;
  }
  &_left {
    justify-content: flex-start;
    margin-bottom: 8px;
  }
}

// bootstrap

$font-family-sans-serif: "Proxima-Nova", sans-serif;

@import "~bootstrap/scss/bootstrap.scss";

.gap-5 {
  gap: 2.25rem !important;
}
