@import "../../assets/scss/variables";

.button {
  height: 40px;
  min-height: 40px;
  padding-left: 24px;
  padding-right: 24px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  outline: none;
  font-weight: 600;
  font-size: 0.875rem;

  &:disabled {
    background-color: transparent;
    border: 1px solid $dark40;
    color: $dark40;
    cursor: auto;

    &:hover {
      background-color: transparent;
      color: $dark40;
    }
  }

  i {
    margin-right: 11px;
    margin-top: 2px;
  }

  &_big {
    text-align: center;
    width: 100%;
  }

  &_red {
    background-color: $red;

    &:hover {
      background-color: $red;
    }
  }

  &_redLight {
    background-color: transparent;
    border: 1px solid $red;
    color: $red;

    &:hover {
      background-color: $red;
      color: #fff;
    }
  }

  &_silver {
    background-color: $dark10;
    color: $dark;

    &:hover {
      background-color: $dark10;
      color: $dark;
    }
  }

  &_white {
    color: $blue;
    background-color: #fff;

    &:hover {
      background-color: #fff;
    }
  }
}
