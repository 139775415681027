$blue: #056ae7;
$blue80: #2D6AF6;
$blue70: #5097ee;
$blue40: #9bc3f5;
$blue10: #e6f0fd;
$blue05: #fafcff;
$red: #d61818;
$red40: #ffbfbf;
$popupBackGround: rgba(20, 20, 22, 0.3);
$shadow: 0px 2px 6px rgba(161, 161, 162, 0.2);
$dark: #141416;
$dark70: #5b5b5c;
$dark40: #a1a1a2;
$dark10: #e7e7e8;
$dark05: #fcfcfc;
$green70: #81cb91;

